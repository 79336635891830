import React, { useEffect } from 'react';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const CitizenCharter = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<Header />
			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 c col-md">
							<h1 className="display-4 mb-2">Citizens Charter</h1>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<h3 className="mb-5 font-weight-bold">
								Introduction to Cookiejar Technologies Private Limited (Finvu
								Account Aggregator)
							</h3>
							<p className="text-gray-800">
								RBI, along with other Financial Services Regulators - SEBI, IRDA
								and PFRDA - have created this framework for the financial
								services industry. Current framework recognizes three main
								bodies in the ecosystem - Account Aggregators (AA), Financial
								Information Providers (FIP) and Financial Information Users
								(FIU). In this framework, an Account Aggregator (AA) acts as a
								Consent Manager to enable sharing of financial data across
								regulated financial entities, besides obtaining customer
								consent.
							</p>
							<p className="text-gray-800">
								RBI introduced a new category of NBFC called – NBFC Account
								Aggregator in Sep 2016, the guidelines have been published at:{' '}
								<a href="https://rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=10598">
									https://rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=10598
								</a>
							</p>
							<p className="text-gray-800">
								Cookiejar Technologies Private Limited (hereinafter referred as
								Finvu AA), is registered with RBI to provide Account Aggregator
								Services.
							</p>
							<p className="text-gray-800">
								It provides an account aggregator application under the name
								Finvu AA (hereinafter referred as AA Application).
							</p>
							<p className="text-gray-800">
								AA Application helps its end users to retrieve, consolidate, and
								deliver its financial information and share it with a third
								party based on the explicit consent of the user in a secure and
								encrypted manner.
							</p>
							<p className="text-gray-800">
								Finvu AA is a secure platform to provide your consent to share
								sensitive financial data under the RBI approved Account
								Aggregator framework. The app aims at empowering you with
								control over how, when and with whom your data is shared. This
								app also helps you in managing your consents on the Finvu AA
								Platform.
							</p>
							<h3 className="mb-5 font-weight-bold">
								Disclaimer & Application of Charter
							</h3>
							<p className="text-gray-800">
								Information given in this Citizen's Charter is subject to change
								/ revision. This document is limited to Finvu AA. This document
								should not be considered as a legal document creating rights and
								obligations. It is for promoting better understanding between
								Customer and Finvu AA. Only key information on various services
								/ facilities is given in this document. Each service has its own
								detailed terms and conditions, which is made available on
								request.
							</p>
							<p className="text-gray-800">
								The Citizen's Charter is not legally enforceable and, therefore,
								is non-justiciable. However, it is a tool for facilitating the
								delivery of services to citizens with specified standards,
								quality, and time frame, etc., with commitments from the
								organization and its clients, and in furtherance of the same
								this Charter applies to all products and services of Finvu AA as
								listed below.
							</p>
							<p className="text-gray-800">
								The various commitments and timelines spelt out in the Citizen's
								Charter are subject to fulfilment of the requirements of the
								RBI's Master Directions and various circulars or communications
								regarding the Account Aggregator services and where there is a
								conflict between the Charter and the current instructions of
								RBI, RBI's instructions would prevail.
							</p>
							<h3 className="mb-5 font-weight-bold">Definitions</h3>
							<p>
								In this Citizen Charter unless the context otherwise requires,
							</p>
							<p className="text-gray-800">
								(i) “<b>AA Master Directions</b>” means Master Direction-
								Non-Banking Financial Company - Account Aggregator (Reserve
								Bank) Directions, 2016 (DNBR.PD.009/03.10.119/2016-17) as
								modified or updated time to time.
							</p>
							<p className="text-gray-800">
								(ii) “<b>Account Aggregator</b>”means Cookiejar Technologies
								Private Limited (Finvu AA)
							</p>
							<p className="text-gray-800">
								(iii) “<b>Bank</b>” means a banking company; or a corresponding
								new bank; or the State Bank of India; or a subsidiary bank; or
								such other bank which the RBI may, by notification, specify for
								the purposes of the AA Master Directions; and a co-operative
								bank as defined under clause (cci) of section 5 read with
								section 56 of the Banking Regulation Act, 1949 (10 of 1949)
							</p>
							<p className="text-gray-800">
								(iv) “<b>Business of an account aggregator</b>” means the
								business of providing under a contract, the service of,
								retrieving or collecting such financial information pertaining
								to its Customer, as may be specified by the RBI from time to
								time; and consolidating, organizing, and presenting such
								information to the Customer or any other Financial Information
								User as may be specified by the RBI.
							</p>
							<p className="text-gray-800">
								(v) “<b>Customer</b>” means a ‘person' who has entered into a
								contractual arrangement with the Account Aggregator to avail
								services provided by the Account Aggregator.
							</p>
							<p className="text-gray-800">
								(vi) “<b>Financial Information</b>” s means information in
								respect of the following with Financial Information Providers:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(a) Bank deposits including fixed deposit accounts, savings
									deposit accounts, recurring deposit accounts and current
									deposit accounts
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(b) Deposits with NBFCs</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(c) Structured Investment Product (SIP)
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(d) Commercial Paper (CP)</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(e) Certificates of Deposit (CD)
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(f) Government Securities (Tradable)
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(g) Equity Shares</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(h) Bonds</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(i) Debtentures</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(j) Mutual Fund Units</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(k) Exchange Trade Funds</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(l)Indian Depository Receipts</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(m) CIS (Collective Investment Schemes) units
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(n) Alternate Investment Funds (AIF) units
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(o) Insurance Policies</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(p) Balances under the National Pension System (NPS)
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(q) Units of Infrastructure Investment Trusts
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(r) Units of Real Estate Investment Trusts
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(s) Goods and Services Tax (GST) Returns, viz. Form GSTR-1 and
									Form GSTR-3B
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(t) Any other information as may be specified by the Bank for
									the purposes of AA Master Directions, from time to time.
								</p>
							</div>
							<p className="text-gray-800">
								“<b>Financial Information Providers</b>” or "FIP" means bank,
								non-banking financial company, asset Management Company,
								depository, depository participant, insurance company, insurance
								repository, pension funds, Goods and Services Tax Network (GSTN)
								and such other entity as may be identified by the RBI for the
								purposes of the AA Master Directions, from time to time.
							</p>
							<p className="text-gray-800">
								“<b>Financial Information Users</b>” or "FIU" means all the
								entity registered / regulated by any financial sector regulator
							</p>
							<p className="text-gray-800">
								“<b>Person</b>” means an individual, a Hindu undivided family, a
								company, a firm, association of persons or a body of
								individuals, whether incorporated or not, and every artificial
								juridical person, not falling within any of the preceding
								sub-clauses.
							</p>
							<p className="text-gray-800">
								“<b>RBI</b>” means Reserve Bank of India.
							</p>
							<h3 className="mb-5 font-weight-bold">Service Standards</h3>
							<p className="text-gray-800">
								Business of Account Aggregator will be entirely Information
								Technology (IT) driven Finvu AA is committed to fulfil
								requirements of its Customers, hence, Finvu AA commits to deploy
								all the required IT infrastructure in place which is necessary
								and required for provision of services of account aggregator.
							</p>
							<p className="text-gray-800">
								Where manual intervention may be required, such as handling
								customer on boarding in case of non-individuals OR any customer
								grievances, Finvu AA would strive to revert to customer queries
								and carry out the processes normally within one working day OR
								as per the Grievance redressal mechanism provided in Grievance
								Redressal Policy of Finvu AA.
							</p>
							<h3 className="mb-5 font-weight-bold">
								Our Commitments to Customer
							</h3>
							<p>Finvu AA is committed to:</p>
							<p className="text-gray-800">
								<ul>
									<li>
										Act courteously, fairly, and reasonably in all our dealings
										with Customer; Communicate the Financial Information on "As
										is" or "As Available Basis", and hence will be data blind
										i.e., it will not store any Financial Information of
										Customer.
									</li>
									<li>
										Ensure that documents and procedures are clear and not
										misleading, and Customer are given clear information about
										the products and services of Finvu AA;
									</li>
									<li>
										Render services of account aggregator to Customer on the
										explicit consent;
									</li>
									<li>
										Any Information of Customer shall be shared only with
										him/her, or any other Financial Information User authorised
										by him/ her;
									</li>
									<li>
										Provide an enabling IT platform for data exchange with
										consent between Customer and FIU where FIP is the custodian
										of the Financial Information.
									</li>

									<li>
										Communicate the Financial Information on "As is" or "As
										Available Basis", and hence will be data blind i.e., it will
										not store any Financial Information of Customer.
									</li>
									<li>
										Keep pricing policy of Services to Customer fair and
										transparent;
									</li>
									<li>
										Strictly comply with the internal guidelines and AA Master
										Direction.
									</li>
									<li>
										Treat all personal information as private and confidential;
										and operate secure and reliable IT framework; and
									</li>
									<li>
										Publicize our Citizens Charter have copies available and
										make sure that staff are trained to put it into practice.
									</li>
								</ul>
							</p>
							<h3 className="mb-5 font-weight-bold">
								Our Commitments to Financial Information User
							</h3>
							<p>Finvu AA is committed to:</p>
							<p className="text-gray-800">
								<ul>
									<li>
										Act courteously, fairly, and reasonably in all our dealings
										with FIU;
									</li>
									<li>
										Ensure that documents and procedures are clear and not
										misleading, and FIU are given clear information about the
										products and services of Finvu AA;
									</li>
									<li>
										Any Information of Customer shall be shared only with
										him/her, or Financial Information User authorised by him/
										her;
									</li>
									<li>
										Provide an enabling IT platform for data exchange with
										consent between Customer and FIU where FIP is the custodian
										of the Financial Information.
									</li>
									<li>
										Transfer the Financial Information on "As is" or "As
										Available Basis", and hence will be data blind i.e., it will
										not store any Financial Information of Customer shared with
										the FIU.
									</li>
									<li>
										All responses of the Financial Information Provider shall be
										in real time.
									</li>
									<li>
										Financial Information received by the FIU is transmitted in
										encrypted form by the FIP and financial details and current
										position of the Customer is as provided by the FIP.
									</li>
								</ul>
							</p>
							<h3 className="mb-5 font-weight-bold">Consent Architecture</h3>
							<p>
								The consent of the Customer obtained by the Account Aggregator
								(Finvu AA) shall be a standardised consent artefact in
								compliance with AA Master Directions. It will contain the
								following details, namely: -
							</p>
							<p className="text-gray-800">
								<ul>
									<li>
										Identity of the Customer and optional contact information;
									</li>
									<li>The nature of the Financial Information requested;</li>
									<li>Purpose of collecting such information;</li>
									<li>
										The identity of the recipients of the information, if any;
									</li>
									<li>
										URL or other address to which notification needs to be sent
										every time the consent artefact is used to access
										information.
									</li>
									<li>
										Consent creation date, expiry date, identity, and signature/
										digital signature of the Account Aggregator; and
									</li>
									<li>Any other attribute as may be prescribed by the RBI.</li>
								</ul>
							</p>
							<p>
								Upon revocation of Consent, the Financial Information Provider
								will be notified in real time. Further information sharing will
								be upon a fresh consent artefact.
							</p>
							<h3 className="mb-5 font-weight-bold">
								Expectation from Customer
							</h3>
							<p>Customers shall:</p>
							<p className="text-gray-800">
								(a) You represent and warrant that all information that is
								provided through or in relation to the Services is complete,
								true, and correct on the date of agreeing to these Terms and
								shall continue to be complete, true, and correct while you avail
								the Services. Should any information that you provide change
								during the existence of these Terms, you undertake to
								immediately bring such change to our notice and update the same
								through your Profile promptly. You acknowledge that we exercise
								no control over the content of the information transmitted by
								you through the Platform. We do not accept any responsibility or
								liability for any loss or damage that you may suffer or incur if
								any information, documentation, material, or data provided to
								avail the Services is incorrect, incomplete, inaccurate, or
								misleading, or if you fail to disclose any material fact.review
								the details of all requests that they receive from FIUs through
								Finvu AA to transfer data from FIPs to such FIU in order to
								verify the nature and quantum of data requested, the purpose for
								which such data is being collected and the duration for which it
								will be retained by the FIU;
							</p>
							<p className="text-gray-800">
								(b) only provide their consent after having duly verifying the
								details of the request to transfer data and any consent so
								provided shall be binding on the Customer;
							</p>
							<p className="text-gray-800">
								(c) be responsible for the confidentiality, safekeeping, and
								security of their account details, including but not limited to,
								login and other credentials, required to access and use their
								account on the AA ecosystem;
							</p>
							<p className="text-gray-800">
								(d) be solely responsible for all communications exchanged
								between Customer and Finvu AA through the AA Ecosystem and/or
								any transaction or activity conducted, or purported to be
								conducted with the AA;
							</p>
							<p className="text-gray-800">
								(e) duly discharge payment obligations as arise on account of
								use of the services, and
							</p>
							<p className="text-gray-800">
								(f) notify Finvu immediately if there is any unauthorised access
								to or use of their accounts.
							</p>
							<h3 className="mb-5 font-weight-bold">Rights of Customer</h3>
							<p>The Customer shall, have the right to:</p>
							<p className="text-gray-800">
								<ul>
									<li>
										be informed by Finvu AA all the attributes specified in the
										consent artefact in relation to any consent sought to be
										obtained from such Customer for the transfer of data from
										FIPs to the FIU through Finvu AA and/or for transfer of data
										on Customer's own request from FIPs to Customers through
										Finvu AA, as the case may be;
									</li>
									<li>
										Be informed of all the information received in consent as
										per above mentioned consent artefact architecture.
									</li>
									<li>To approve OR reject consent request received.</li>
									<li>
										to access a record of all the consents provided, the details
										of the subsequent data flows and the FIUs with whom the
										corresponding data has been shared for such period of time
										as specified under applicable laws from time to time;
									</li>
									<li>
										to any time revoke any consent that has been provided to an
										FIU; and
									</li>
									<li>
										To deregister from Finvu AA at any time by following a
										simple process that allows the Customer to delink all
										connected accounts, revoke all active consents, and download
										the consent and transaction history.
									</li>
									<li>
										to raise a grievance with the Grievance Resolution Officer
										of the Finvu AA in accordance with Grievance Redressal
										Policy of Account Aggregator;
									</li>
									<li>
										To escalate grievance with relevant authorities in case of
										non-redressal within prescribed time limit mentioned in
										grievance redressal mechanism.
									</li>
								</ul>
							</p>
							<h3 className="mb-5 font-weight-bold">
								Sharing of financial information
							</h3>
							<p className="text-gray-800">
								<ul>
									<li>
										Account Aggregator should only share the data to a FIU from
										FIP based on the valid consent artefact
									</li>
									<li>
										FIP, upon being presented the consent artefact shall verify:
										(a)Validity of consent; (b) Specified dates and usage; and
										(c) The credentials of the Account Aggregator through
										appropriate means.
									</li>
									<li>
										FIP, upon successful verification, shall digitally sign the
										financial information and securely forward the information
										in encrypted form to the Account Aggregator as per terms
										contained in the consent artefact.
									</li>
								</ul>
							</p>
							<h3 className="mb-5 font-weight-bold">Data Security</h3>
							<p className="text-gray-800">
								<ul>
									<li>
										Finvu AA will take appropriate steps to protect the
										information shared by the Customer. It has in place
										technology and security features and strict policy
										guidelines to safeguard the privacy of Financial Information
										from unauthorized access and improper use or disclosure.
									</li>
									<li>
										Finvu AA is committed to keep its security procedures
										updated and complaint with applicable regulations.
									</li>
									<li>
										Adequate safeguards in the IT systems shall be adopted to
										ensure that the information provided and stored is protected
										against unauthorised access, alteration, destruction,
										disclosure or dissemination of records and data.
									</li>
									<li>
										Appropriate measures shall be taken to prevent loss of data
										due to disasters or any such natural calamities, and thereby
										strive to adopt an effective Disaster Risk
										Management/Business Continuity plan.
									</li>
									<li>
										Information System Audit of the internal systems and
										processes shall be in place and shall be conducted at least
										once every two years by CISA certified external auditors.
										Report generated by the said external auditor shall be
										submitted to the Regional Office of the Department of
										Non-Banking Supervision of the Bank, within one month of
										submission of the report by the external auditor.
									</li>
								</ul>
							</p>
							<h3 className="mb-5 font-weight-bold">
								Our Grievance Redressal Mechanism
							</h3>
							<p className="text-gray-800">
								We believe while involving into various systems, processes and
								involves human handling it is possible few of issues may occur
								and should be handle with proper redressal mechanism to ensure
								accurate resolution in timely manner. Customer can access our
								Grievance Redressal Policy at{' '}
								<a href="https://finvu.in/grievance">
									https://finvu.in/grievance
								</a>
							</p>
							<p className="font-weight-bold">Grievance Process:</p>
							<p>
								Customer shall raise the AA related Grievances on
								support@cookiejar.co.in by providing below details. The issues
								shall resolve within 14 working days.
							</p>
							<ul style={{ listStyle: 'none' }}>
								<li>VUAID </li>
								<li>Contact No </li>
								<li>Issue Description</li>
							</ul>
							<p className="font-weight-bold">Important Note:</p>
							<p className="text-gray-800">
								As per RBI guidelines mentioned in master direction
								(DNBR.PD.009/03.10.119/2016-17) for NBFC Account Aggregator
								complaints if any, should be raise within 30 days from the
								occurrence. Any complaints received after 30 days of occurrence
								will be taken in good faith and best effort basis.
							</p>
							<p>
								In case complaint raised on aforesaid email id does not get
								resolved within 14 days, customer can contact our grievance
								officer. Grievance officer details are as follows:
							</p>

							<p className="text-gray-800">
								<b>Email Address:</b> grievance@cookiejar.co.in
							</p>
							<p className="text-gray-800">
								<b>Name:</b> Rucha Ghude
							</p>
							<p className="text-gray-800">
								Customer can also raise complaint via sending complaint letter
								to our corporate address addressing to below details:
							</p>
							<p className="text-gray-800">
								<ul style={{ listStyle: 'none' }}>
									<li>Grievance Redressal Officer </li>
									<li>Cookiejar Technologies Private Limited.</li>
									<li>WeWork Raheja Woods,</li>
									<li>3rd floor,</li>
									<li>
										next to Talera Park Society, Kalyani Nagar, Pune,
										Maharashtra, India, 411006{' '}
									</li>
								</ul>
							</p>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default CitizenCharter;
