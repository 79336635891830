import React, { useEffect } from 'react';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Header />
			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h1 className="display-4 mb-0">Finvu Privacy</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<p className="text-gray-800">
								This Privacy Policy (“<b>Policy</b>”) outlines Cookiejar
								Technologies Private Limited’s (“<b>Finvu”, “we”, or “us”</b>)
								practices in relation to the storage, use, processing, and
								disclosure of personal data that you have chosen to share with
								us when you access our website https://finvu.in/ and mobile
								application Finvu (collectively, the “<b>Platform</b>”), or
								personal data that we may have access to in relation to your use
								of Platform or access to our account aggregation services
								(collectively, “<b>Services</b>”).
							</p>
							<p className="text-gray-800">
								At Finvu, we are committed to protecting your personal data and
								respecting your privacy. Please read the following terms of the
								Policy carefully to understand our practices regarding your
								personal data, and how we will treat it. This Policy sets out
								the basis on which any personal data we collect from you or
								about you, or that you provide to us, will be processed by us.
							</p>
							<p className="text-gray-800">
								Capitalised words in the Policy shall have the same meaning
								ascribed to them in the Terms and Conditions (“<b>Terms</b>”),
								available at https://finvu.in/terms. Please read this Policy in
								consonance with the Terms. By using the Platform, you consent to
								the collection and disclosure of your personal data in
								accordance with, and are agreeing to be bound by, this Policy.
								We will not collect any information from you, except where it is
								knowingly and explicitly provided by you.
							</p>

							<p className="text-gray-800 mb-6 mb-md-8">
								This Policy together with any terms of the arrangement we have
								with you applies to your use of the Services.
							</p>

							<h3 className="mb-5">1. THE DATA WE COLLECT ABOUT YOU</h3>

							<p className="text-gray-800">
								(a) Personal data, or personal information, means any
								information about an individual from which that person can be
								identified. It does not include data where the identity has been
								removed – for example, anonymous data.
							</p>
							<p className="text-gray-800">
								(b) Please note that in accordance with applicable laws, we do
								not store or use any financial data that you choose to share
								with third parties or otherwise transmit through our Services (“
								<b>Financial Data</b>”). You may use our Services to transmit
								and share Financial Data with third parties; however, we will
								not have access to such data. For more information on how your
								Financial Data is transmitted through our Services, please
								review our Terms.
							</p>
							<p className="text-gray-800">
								(c) We may collect, use, store and transfer different kinds of
								personal data about you to provide you with, or in connection
								with, the Services. Such personal data includes:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(i) Data pertaining to your identity and related data, such as
									your first and last name, username or similar identifiers,
									gender, title, passwords, identity document and proof of
									addresses, purchases or orders of or through our Services,
									feedback, survey responses, etc.;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ii) Contact data, including email addresses, phone numbers,
									delivery addresses, business addresses, etc.;
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iii) Data we collect in connection with our KYC processes
									when you choose to access certain features of our Services,
									including documents issued by the government such as Aadhaar,
									driving licences, PAN cards, voter ID cards, ration cards,
									etc.;
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iv) Data about your device, including but not limited to:
								</p>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-success-soft mt-0 ml-5">
									<i className="fe fe-chevrons-right"></i>
								</span>
								<p>Location – location data recorded on your device; and</p>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-success-soft mt-0 ml-5">
									<i className="fe fe-chevrons-right"></i>
								</span>
								<p>
									Device Information – including hardware model, operating
									system and version, IMEI and serial numbers, user profile
									information, IP addresses, browser types and versions, time
									zone settings, and Wi-Fi and mobile networks.
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(v) Usage data, including information about how you use our
									Services; and
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(vi) Marketing and communications data, including your
									preferences in receiving marketing from us and our third
									parties and your communication preferences.
								</p>
							</div>

							<p className="text-gray-800">
								(d) We also collect, use, and share aggregated data such as
								statistical data for any purpose. Aggregated data could be
								derived from your personal data but is not considered personal
								data under applicable laws. For example, we may aggregate your
								usage data to calculate the percentage of users accessing a
								specific feature of the Services. However, if we combine or
								connect aggregated data with your personal data, we treat the
								combined data as personal data, which will be treated in
								accordance with other terms of this Policy.
							</p>

							<p className="text-gray-800">
								(e) Where we need to collect personal data by law, or under the
								terms of the arrangement we have with you, and if you fail to
								provide that data as and when requested by us, we may not be
								able to perform our obligations under the arrangement we have
								with you or are trying to enter into with you (for example, to
								provide you with features of the Services). In this case, we may
								have to cancel or limit your access to the Services, but we will
								notify you if this is the case at the time.
							</p>

							<h3 className="mb-5">
								2. HOW YOUR PERSONAL AND FINANCIAL DATA IS COLLECTED
							</h3>

							<p className="text-gray-800">
								(a) We use different methods to collect and process data about
								you.
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(i) <b>Information you give us</b> – This is the information
									(including identity, contact, and marketing and communications
									data) you consent to giving us about you when you use our
									Services or by corresponding with us (for example, by email or
									chat). It includes information you provide when you register
									to use the Services, use an in-Platform feature, or share data
									through the Services, through other activities commonly
									carried out in connection with the Services, and when you
									report a problem with the Platform and our Services. If you
									contact us, we will keep a record of the information shared
									during the correspondence.
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ii) <b>Information we collect about you and your device</b> –
									Each time you visit our Platform or use one of our Services,
									we will automatically collect personal data including device
									and usage data. We collect this data using cookies and other
									similar technologies.
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iii){' '}
									<b>
										Information we receive from other sources including third
										parties
									</b>{' '}
									– We will receive personal data about you from various third
									parties as set out below:
								</p>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-success-soft mt-0 ml-5">
									<i className="fe fe-chevrons-right"></i>
								</span>
								<p>Analytics providers such as Google and Facebook;</p>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-success-soft mt-0 ml-5">
									<i className="fe fe-chevrons-right"></i>
								</span>
								<p>Advertising networks;</p>
							</div>
							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-success-soft mt-0 ml-5">
									<i className="fe fe-chevrons-right"></i>
								</span>
								<p>Search information providers;</p>
							</div>

							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-success-soft mt-0 ml-5">
									<i className="fe fe-chevrons-right"></i>
								</span>
								<p>
									Identity and contact data from data brokers, aggregators, or
									social networks; and
								</p>
							</div>

							<div className="d-flex">
								<span className="badge badge-rounded-circle badge-success-soft mt-0 ml-5">
									<i className="fe fe-chevrons-right"></i>
								</span>
								<p>
									Identity and contact data from publicly available sources.
								</p>
							</div>

							<h3 className="mb-5">3. AUTHENTICITY OF INFORMATION</h3>

							<p className="text-gray-800">
								(a) We have taken all reasonable steps to ensure that the
								information on the Platform is authentic.
							</p>

							<p className="text-gray-800">
								(b) You agree that the personal data you provide us with is
								true, correct, and accurate. We shall not be liable for any
								incorrect or false information or personal data that you might
								provide.
							</p>

							<h3 className="mb-5">
								4. HOW WE USE YOUR PERSONAL DATA AND FOR WHAT PURPOSES
							</h3>

							<p className="text-gray-800">
								(a) We will only use your personal data as the law allows us to.
								Most commonly, we will use your personal data to provide you
								with the Services, or where we need to comply with a legal
								obligation.
							</p>

							<p className="text-gray-800">
								(b) You understand that when you consent to providing us with
								your personal data, you also consent to us sharing such data
								with third parties. However such personal data will be shared
								with third parties only for the purpose of providing you the
								Services. You are aware that by using our Services on the
								Platform, you authorise us, our associate partners, and
								affiliates to contact you via email, phone, or otherwise. This
								is to ensure that you are aware of all the features of the
								Services.
							</p>

							<p className="text-gray-800">
								(c) You are aware that any and all information pertaining to
								you, whether or not you directly provide it to us (via the
								Services or otherwise), including but not limited to personal
								correspondence such as emails, instructions from you etc., may
								be collected, compiled, and shared by us in order to provide the
								Services to you and you expressly authorise us to do so. This
								may include but not be limited to storage providers, marketing
								partners, data analytics providers, consultants, lawyers, and
								auditors. We may also share this information with our
								subsidiaries, affiliates, or any of their holding companies.
							</p>

							<p className="text-gray-800">
								(d) You agree and acknowledge that we may share data without
								your consent, when it is required by law or by any court or
								government agency or authority to disclose such information.
								Such disclosures are made in good faith and belief that it is
								reasonably necessary to do so for enforcing this Policy or the
								terms of the arrangement we have with you, or in order to comply
								with any applicable laws and regulations.
							</p>

							<p className="text-gray-800">
								(e) In general, we will not disclose personal data except in
								accordance with the following purpose/activity:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(i) to register you as a user of the Platform;
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(ii) to deliver Services;</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iii) to manage our relationship with you including notifying
									you of changes to any Services;
								</p>
							</div>

							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iv) to administer and protect our business and the Services
									including troubleshooting, data analysis and system testing;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(v) to measure and analyse the effectiveness of the service we
									serve you through means which include surveys and feedback;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(vii) to monitor trends so we can improve the Services;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(viii) to perform our obligations that arise out of the
									arrangement we are about to enter or have entered with you;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ix) to enforce the terms of the arrangements we have with
									you;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(x) where it is necessary for our legitimate interests (or
									those of a third party) and your interests and fundamental
									rights do not override those interests; and
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(xi) to comply with a legal or regulatory obligation.
								</p>
							</div>

							<p className="text-gray-800">
								(f) You authorise us to send you electronic alerts and messages
								for details pertaining to registration on the Platform,
								requirements arising out of the provision of Services, and
								advertising.
							</p>
							<p className="text-gray-800">
								(g) Further, you agree to receive promotional and other emails
								and other forms of communication from us. Through such
								communication you will receive information about the latest
								developments on the Services. You may unsubscribe from our
								mailing list at any time, via the unsubscribe option we offer.
							</p>

							<h3 className="mb-5">5. THIRD PARTY SERVICES</h3>

							<p className="text-gray-800">
								(a) Our Services may, from time to time, contain services
								provided by or links to and from the websites of our partner
								networks, and affiliates (“<b>Third Party Services</b>”). Please
								note that the Third Party Services that may be accessible
								through our Services have their own privacy policies. We do not
								accept any responsibility or liability for the policies or for
								any personal data that may be collected through the Third Party
								Services. Please check their policies before you submit any
								personal data to such websites or use their services.
							</p>

							<p className="text-gray-800">
								(b) We use third-party user experience improvement services and
								applications to better understand your behaviour on the
								Services.
							</p>
							<p className="text-gray-800">
								(c) Your relationship with these third parties and their
								services and tools is independent of your relationship with us.
								These third parties may allow you to permit/restrict the
								information that is collected. It may be in your interest to
								individually restrict or enable such data collections.
							</p>
							<p className="text-gray-800">
								(d) The place of processing information depends on each
								third-party service provider and you should review the privacy
								policy of each of the service providers to identify the data
								shared and its purpose. You will be subject to a third party’s
								privacy policy if you opt in to receive communications from
								third parties. We will not be responsible for the privacy
								standards and practices of third parties.
							</p>
							<p className="text-gray-800">
								(e) We may share your personal information with our parent
								company, subsidiaries, joint ventures, or other companies under
								a common control that we may have now or in the future, in which
								case we will require them to honour this Policy.
							</p>

							<h3 className="mb-5">6. COOKIES</h3>

							<p className="text-gray-800">
								(a) We use cookies and/or other tracking technologies to
								distinguish you from other users of the Services and to remember
								your preferences. This helps us to provide you with a good
								experience when you use our Services and also allows us to
								improve the Services.
							</p>

							<p className="text-gray-800">
								(b) We collect data by way of ‘cookies’. Cookies are small data
								files which are sent to your browser from the Platform and are
								stored on your computer or device (hard drive). The cookies
								shall not provide access to data in your computer or device such
								as email addresses or any other data that can be traced to you
								personally. The data collected by way of cookies will allow us
								to administer the Services and provide you with a tailored and
								user-friendly service. The cookies shall enable you to access
								certain features of the Services. Most web browsers and devices
								can be set to notify when you receive a cookie or prevent
								cookies from being sent. If you do prevent cookies from being
								sent, it may limit the functionality that we can provide when
								you visit the Platform or try to access some of the Services.
							</p>
							<p className="text-gray-800">
								(c) Additionally, you may encounter cookies or other similar
								devices on certain pages of the Services that are placed by
								third parties. We do not control the use of cookies by third
								parties. If you send us personal correspondence, such as emails
								or letters, or if other users or third parties send us
								correspondence about your activities in relation to the
								Services, we may collect such information into a file specific
								to you.
							</p>

							<h3 className="mb-5">7. DATA SECURITY</h3>

							<p className="text-gray-800">
								(a) We implement certain security measures including encryption
								and firewalls to protect your personal information from
								unauthorised access and such security measures are in compliance
								with the security practices and procedures as prescribed under
								the Information Technology Act, 2000 and the applicable rules
								(Reasonable Security Practices and Procedures and Sensitive
								Personal Data or Information Rules, 2011). However, you agree
								and acknowledge that the above-mentioned measures do not
								guarantee absolute protection to the personal information, and
								by accessing the Services, you agree to assume all risks
								associated with disclosure of personal information arising due
								to breach of firewalls and secure server software.
							</p>

							<p className="text-gray-800">
								(b) Where we have given you (or where you have chosen) a
								password that enables you to access certain parts of the
								Services, you are responsible for keeping this password
								confidential. We ask you not to share the password with anyone.
							</p>
							<p className="text-gray-800">
								(c) We will comply with the requirements under the Information
								Technology Act, 2000 and the rules made thereunder in the event
								of a data or security risk.
							</p>

							<h3 className="mb-5">8. CHANGE IN PRIVACY POLICY</h3>

							<p className="text-gray-800">
								(a) We keep our Policy under regular review and may amend this
								Policy from time to time, at our sole discretion.
							</p>

							<p className="text-gray-800">
								(b) The terms of this Policy may change and if it does, these
								changes will be posted on this page and, where appropriate,
								notified to you by email. The new Policy may be displayed
								on-screen and you may be required to read and accept the changes
								to continue your use of the Services.
							</p>

							<h3 className="mb-5">9. GRIEVANCE OFFICER </h3>

							<p className="text-gray-800">
								You may contact us at support@cookiejar.co.in with any enquiry
								relating to this Policy or an enquiry relating to your personal
								data (including reviewing or updating). You can also do so by
								writing to our Grievance Officer, established in accordance with
								the Information Technology Act, 2000 and rules made thereunder,
								at the address provided below:
							</p>

							<p className="text-gray-800">
								<b>Name:</b>Rucha Ghude
							</p>
							<p className="text-gray-800">
								<b>Corporate Address:</b> 3rd floor, WeWork Raheja Woods
								Building, Kalyani Nagar, Pune, India, 411006
							</p>
							<p className="text-gray-800">
								<b>Email Address:</b> support@cookiejar.co.in
							</p>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Privacy;
