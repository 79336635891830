import React, { useEffect } from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { Link } from 'react-router-dom';

const NodalOfficer = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const getDetails = () => {
		return (
			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h2 className="display-6 mb-1">
								<span className="text-primary">Nodal Officer</span>
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>Nodal Officer:</b>
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								Rucha Ghude
							</p>
							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>Email id:</b> grievance@cookiejar.co.in
							</p>

							<p className="font-size-inherit text-gray-800 mb-2 mb-md-2">
								<b>Corporate Address:</b>
							</p>
							<p className="font-size-inherit text-gray-800 mb-6 mb-md-6">
								Cookiejar Technologies Pvt Ltd (Finvu),WeWork Raheja Woods, 3rd
								floor, next to Talera Park Society, Kalyani Nagar, Pune,
								Maharashtra, India, 411006
							</p>
						</div>
					</div>
				</div>
			</section>
		);
	};

	return (
		<>
			<Header></Header>
			{getDetails()}
			<Footer></Footer>
		</>
	);
};

export default NodalOfficer;
