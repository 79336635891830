import React, { useEffect } from 'react';
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';

const AboutUs = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Header></Header>

			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h1 className="display-6 mb-2">About Us</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<p className="font-size-lg text-gray-800 mb-6 mb-md-8">
								Cookiejar Technologies Pvt Ltd (brand name Finvu) has been given
								the NBFC-AA (NBFC Account Aggregator) certificate of
								registration* by RBI to provide the services of an Account
								Aggregator (AA) for duly retrieving or collecting,
								consolidating, organizing and presenting financial information
								to the customer or any other financial information user as may
								be specified by RBI.
							</p>

							<p className="font-size-lg text-gray-800 mb-6 mb-md-8">
								Our founding team have collective experience of 50+ years in
								Banking technology and worked for Banks in India and overseas.
								Our experience has been in leading and delivering complex
								integrations for the banks where we worked specifically in areas
								of Payments (SWIFT) and Corporate Banking. Coming from Banking
								and technology and wanting to be part of the digital journey in
								India - Account Aggregator was a natural progression for us.
							</p>

							<h3 className="mb-3">Our Location</h3>

							<p className="text-gray-800">
								We are based out of Pune located on 3rd Floor, WeWork Raheja
								Woods Building, Kalyani Nagar, Pune, India, 411006
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<h3 className="mb-3">Our Partners</h3>

							<p className="text-gray-800">
								We have partnered with and are currently working with multiple
								banking institutions, NBFCs, wealth managers who are early
								adopters of the Account Aggregator ecosystem.
							</p>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-11">
							<p style={{ fontSize: '11px' }}>Note*</p>

							<p style={{ fontSize: '11px' }}>
								The company is having a valid certificate of registration dated
								06th March 2020 by Reserve Bank of India under section 45 IA of
								the Reserve Bank of India Act, 1934. However, (a) the RBI does
								not accept any responsibility or guarantee about the present
								position as to the financial soundness of the company or for the
								correctness of any of the statements or representations made or
								opinions expressed by the company and for the repayment of
								deposits/discharge of liabilities by the Company, and (b)
								neither there is any provision in law to keep, nor does the
								company keep any part of the deposits with the Reserve Bank of
								India and by issuing a Certificate of Registration to the
								company, the Reserve Bank of India, neither accepts any
								responsibility nor guarantees the payment of deposits to any
								depositor or any person who has lent any sum to the company.
							</p>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default AboutUs;
